<template>
  <section class="purchase_order">
    <NavBar />
    <Header
      type="purchaseOrder"
    />
    <div class="content box pb150">
      <Search
        @addGoods="addGoodsHandle"
        @downloadImportTemplate="downloadImportTemplateHandle"
        @search="searchHandle"
        @batchEditNumber="batchEditNumberHandle"
        @batchExportGoods="batchExportGoodsHandle"
        @importGoods="importGoodsHandle"
      />
      <Table
        :tableData="tableData"
        @chooseGoods="chooseGoodsHandle"
        @editGoodsNumber="editGoodsNumberHandle"
        @delete="deleteHandle"
      />
      <Pagination
        :pageIndex.sync="paging.pageIndex"
        :pageSize.sync="paging.pageSize"
        :total="paging.total"
        @change="getTabelData"
      />
      <Footer
        type="purchaseOrder"
        :isAllSelect="isAllSelect"
        @allChoose="allChooseHandle"
        @deleteSelected="deleteSelectedHandle"
        @deleteNoAvailable="deleteNoAvailableHandle"
      />
    </div>
  </section>
</template>

<script>
  import NavBar from 'components/NavBar/index.vue';
  import Header from 'components/Header/index.vue';
  import Search from './components/Search.vue';
  import Table from './components/Table.vue';
  import Pagination from 'components/Pagination/index.vue';
  import Footer from 'components/Footer/index.vue';
  import {
    downloadImportTemplate,
    getTabelData,
    batchEditNumber,
    batchExportGoods,
    chooseGoods,
    editGoodsNumber,
    deleteTabelData,
    allChoose,
    deleteSelected,
    deleteNoAvailable
  } from 'api/purchaseOrderPage.js';
  import {
    batchAddPurchaseOrder
  } from 'api/purchaseOrder.js';

  export default {
    components: {
      NavBar,
      Header,
      Search,
      Table,
      Pagination,
      Footer
    },

    created() {
      this.getTabelData();
    },

    data() {
      return {
        searchData: {
          keyWord: ''
        },
        paging: {
          total: 0,
          pageIndex: 1,
          pageSize: 10
        },
        tableData: [],
        isAllSelect: false
      }
    },

    methods: {
      getTabelData() {
        getTabelData({
          ...this.searchData,
          pageIndex: this.paging.pageIndex,
          pageSize: this.paging.pageSize
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.tableData = res.data.page.records ? res.data.page.records : [];
          if(this.tableData.every(item => item.selected)) {
            this.isAllSelect = true;
          }else {
            this.isAllSelect = false;
          }
          this.paging.total = res.data.page.total;
        });
      },
      addGoodsHandle(goodsList) {
        let goodsCode = [];
        goodsList.forEach(item => {
          goodsCode.push(item.goodsCode);
        });
        batchAddPurchaseOrder({
          goodsCode
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success(res.message);
          this.getTabelData();
          this.$store.dispatch('getPurchaseOrderData');
        });
      },
      downloadImportTemplateHandle() {
        downloadImportTemplate().then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success('操作成功！');
          let a = document.createElement('a');
          a.href = window.URL.createObjectURL(new Blob([res]));
          a.setAttribute('download', '批量导入商品模板(每次最多导入3000品)' + '.xlsx');
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
        });
      },
      searchHandle(keyWord) {
        this.searchData.keyWord = keyWord;
        this.paging.pageIndex = 1;
        this.paging.pageSize = 10;
        this.getTabelData();
      },
      batchEditNumberHandle(formData) {
        batchEditNumber(formData).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success(res.message);
          this.getTabelData();
          this.$store.dispatch('getPurchaseOrderData');
        });
      },
      batchExportGoodsHandle() {
        batchExportGoods().then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success('操作成功！');
          let a = document.createElement('a');
          a.href = window.URL.createObjectURL(new Blob([res]));
          a.setAttribute('download', '商品列表' + '.xlsx');
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
        });
      },
      chooseGoodsHandle(goodsCode) {
        chooseGoods(goodsCode).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.getTabelData();
          this.$store.dispatch('getPurchaseOrderData');
        });
      },
      editGoodsNumberHandle(obj) {
        editGoodsNumber(obj).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.getTabelData();
          this.$store.dispatch('getPurchaseOrderData');
        });
      },
      deleteHandle(goodsCode) {
        deleteTabelData({
          goodsCode: [goodsCode]
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success(res.message);
          this.getTabelData();
          this.$store.dispatch('getPurchaseOrderData');
        });
      },
      allChooseHandle() {
        allChoose().then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.getTabelData();
          this.$store.dispatch('getPurchaseOrderData');
        });
      },
      deleteSelectedHandle() {
        deleteSelected().then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success(res.message);
          this.getTabelData();
          this.$store.dispatch('getPurchaseOrderData');
        });
      },
      deleteNoAvailableHandle() {
        deleteNoAvailable().then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success(res.message);
          this.getTabelData();
          this.$store.dispatch('getPurchaseOrderData');
        });
      },
      importGoodsHandle() {
        this.getTabelData();
        this.$store.dispatch('getPurchaseOrderData');
      }
    }
  }
</script>

<style lang="less" scoped>
  .purchase_order {
    
  }
</style>
