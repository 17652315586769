import request from 'network/request';

export function getGoodsList(params) {
  return request({
    url: '/goods/home/extend/postSearch/po',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
