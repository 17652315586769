<template>
  <section class="footer" :style="{'bottom': isWhat ? '350px' : '50px'}">
    <div class="content frsbc p030">
      <div class="left frfsc" v-if="token">
        <div class="all_select mr30" v-if="type === 'purchaseOrder'">
          <el-checkbox v-model="allSelect" @change="allChoose">全选</el-checkbox>
        </div>
        {{type === 'purchaseOrder' ? '已选商品' : '采购单商品'}}<span class="ml15">{{product}}品/{{piece}}件</span>
        <el-popconfirm title="确认要删除所选商品吗？" confirm-button-text="确定" cancel-button-text="取消" @confirm="deleteSelected" v-if="type === 'purchaseOrder'">
          <el-button slot="reference" class="delete_selected ml30" type="text">删除选中</el-button>
        </el-popconfirm>
        <div class="line" v-if="type === 'purchaseOrder'"></div>
        <el-popconfirm title="确认要删除所有缺货商品吗？" confirm-button-text="确定" cancel-button-text="取消" @confirm="deleteNoAvailable" v-if="type === 'purchaseOrder'">
          <el-button slot="reference" class="delete_no_available" type="text">删除无货</el-button>
        </el-popconfirm>
      </div>
      <div class="left" v-else>您还未登录！点击立即登录，查看采购单商品详情</div>
      <div class="right frfsc">
        <div class="price" v-if="token">
          <div class="total_price">{{type === 'settlementPage' ? '应付总计' : '预估总价'}}<span>￥{{count !== 0 ? count : cartMoney}}</span></div>
          <div class="describe mt5" v-if="regularFlag != 2 && (type === 'home' || type === 'purchaseOrder' || type === 'goodsDetail')">
            {{
              regularFlag == 2 ? '' :
              $store.state.purchaseOrder.applyWholesale ? `你已满${fullAmount}元，结算时可享批发价` : `满${fullAmount}元可享批发价，还差${difference.toFixed(2)}元`
            }}
          </div>
        </div>
        <div class="operation ml15" @click="goPurchaseOrder" v-if="token">{{type === 'purchaseOrder' ? '去结算' : type === 'settlementPage' ? '去支付' : '进入我的采购单'}}</div>
        <div class="operation" @click="goPurchaseOrder" v-else>立即登录</div>
      </div>
    </div>
  </section>
</template>

<script>
  import {
    getRegularFlag
  } from 'utils/user.js';

  export default {
    created() {
      if(this.token) {
        this.$store.dispatch('getPurchaseOrderData');
      }
    },

    props: {
      type: {
        type: String,
        default: ''
      },
      isAllSelect: {
        type: Boolean,
        default: false
      },
      addressId: {
        type: Number,
        default: 0
      },
      count: {
        type: Number,
        default: 0
      },
      isWhat: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        regularFlag: getRegularFlag(), // 1 银卡 2 金卡
        allSelect: false
      }
    },

    computed: {
      token() {
        return this.$store.state.user.token;
      },
      product() {
        return this.$store.state.purchaseOrder.product;
      },
      piece() {
        return this.$store.state.purchaseOrder.piece;
      },
      cartMoney() {
        return this.$store.state.purchaseOrder.cartMoney;
      },
      fullAmount() {
        return this.$store.state.purchaseOrder.fullAmount;
      },
      difference() {
        return this.$store.state.purchaseOrder.difference;
      }
    },

    methods: {
      clickHere() {
        this.$router.push({
          name: 'introducePage'
        });
      },
      allChoose() {
        this.$emit('allChoose');
      },
      deleteSelected() {
        if(this.piece == 0) {
          this.$message.warning('请选择需要删除的商品！');
          return;
        }
        this.$emit('deleteSelected');
      },
      deleteNoAvailable() {
        this.$emit('deleteNoAvailable');
      },
      goPurchaseOrder() {
        if(!this.token) {
          this.$router.push({
            name: 'login'
          });
          return;
        }
        if(this.type === 'purchaseOrder' && this.piece <= 0) {
          this.$message.warning('请选择需要结算的商品！');
          return;
        }
        switch(this.type) {
          case 'purchaseOrder':
            this.$router.push({
              name: 'settlementPage'
            });
          return;
          case 'settlementPage':
            this.$emit('goPayPage', this.addressId);
          return;
          case 'goodsDetail':
            this.$router.replace({
              name: 'purchaseOrder'
            });
          return;
          default:
            this.$router.push({
              name: 'purchaseOrder'
            });
          return;
        }
      }
    },

    watch: {
      isAllSelect: {
        handler(newValue) {
          this.allSelect = newValue;
        },
        immediate: true
      }
    }
  }
</script>

<style lang="less" scoped>
  .footer {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    transition: bottom 0.5s;
    .warning {
      width: 100%;
      height: 60px;
      padding: 15px 30px;
      background: var(--colorfffaec);
      .icon {
        width: 28px;
        height: 28px;
      }
      .text {
        font-size: var(--fontSize12);
        color: var(--colored6d00);
        font-weight: 500;
        span {
          color: var(--color1890ff);
          cursor: pointer;
        }
      }
    }
    .content {
      width: 1200px;
      height: 100px;
      background: var(--colorfff);
      border: 1px solid var(--colorececec);
      border-top: 4px solid var(--colored6d00);
      box-shadow: 0px 8px 9px 0px var(--colorf1f1f1);
      .left {
        font-size: var(--fontSize16);
        color: var(--color7c8b99);
        font-weight: 500;
        span {
          font-size: var(--fontSize16);
          color: var(--color000);
          font-weight: bold;
        }
        .delete_selected {
          font-size: var(--fontSize12);
          color: var(--color1890ff);
          font-weight: 500;
          cursor: pointer;
        }
        .delete_no_available {
          font-size: var(--fontSize12);
          color: var(--color1890ff);
          font-weight: 500;
          cursor: pointer;
        }
        .line {
          margin: 0 15px;
          width: 1px;
          height: 12px;
          background-color: var(--color1890ff);
        }
      }
      .right {
        .price {
          text-align: right;
          .total_price {
            font-size: var(--fontSize12);
            color: var(--color7c8b99);
            font-weight: 500;
            span {
              font-size: var(--fontSize20);
              font-weight: bold;
              color: var(--colorfe1818);
            }
          }
          .describe {
            font-size: var(--fontSize12);
            color: var(--colored6d00);
            font-weight: 500;
          }
        }
        .operation {
          width: 180px;
          height: 60px;
          background: var(--colored6d00);
          font-size: var(--fontSize20);
          color: var(--colorfff);
          font-weight: bold;
          text-align: center;
          line-height: 60px;
          cursor: pointer;
        }
        .no_members {
          background: var(--colorccc);
        }
      }
    }
  }
</style>
