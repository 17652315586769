<template>
  <section class="teble">
    <el-table class="mt50" :data="tableData" @selection-change="handleSelectionChange" stripe border>
      <el-table-column label="" prop="selected" width="50" align="center">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.selected" @change="chooseGoods($event, scope.row.goodsCode)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="商品ISBN" prop="barCode" align="center"></el-table-column>
      <el-table-column label="商品编号" prop="goodsCode" align="center"></el-table-column>
      <el-table-column label="商品图片" prop="appGoodsSmPicUrl" align="center">
        <template slot-scope="scope">
          <img class="img" :src="scope.row.appGoodsSmPicUrl">
        </template>
      </el-table-column>
      <el-table-column label="商品名称" prop="goodsName" align="center"></el-table-column>
      <el-table-column label="定价（元）" prop="basePrice" align="center"></el-table-column>
      <el-table-column label="单价（元）" :prop="unionFlag == 1 ? 'unionPrice' : $store.state.purchaseOrder.applyWholesale ? 'tradePrice' : 'extendPrice'" align="center"></el-table-column>
      <el-table-column label="折扣" :prop="unionFlag == 1 ? 'unionDiscount' : $store.state.purchaseOrder.applyWholesale ? 'discountTrade' : 'discountRate'" align="center">
        <template slot-scope="scope">
          <span>{{unionFlag == 1 ? scope.row.unionDiscount : $store.state.purchaseOrder.applyWholesale ? scope.row.discountTrade : scope.row.discountRate}}折</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="goodStatus" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.goodStatus == 1">有货</span>
          <span v-else>无货</span>
        </template>
      </el-table-column>
      <el-table-column label="数量" prop="number" width="200" align="center">
        <template slot-scope="scope">
          <el-input-number class="input-number150" v-model="scope.row.number" @change="editGoodsNumber($event, scope.row.goodsCode)" :min="1" :max="scope.row.validNum"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-popconfirm title="确认要删除该商品吗？" confirm-button-text="确定" cancel-button-text="取消" @confirm="deleteClick(scope.row.goodsCode)">
            <el-button slot="reference" type="text">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
  import {
    getRegularFlag,
    getUnionFlag
  } from 'utils/user.js';

  export default {
    props: {
      tableData: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        regularFlag: getRegularFlag(), // 1 银卡 2 金卡
        unionFlag: getUnionFlag(),
        multipleSelection: []
      }
    },

    methods: {
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      chooseGoods(_, goodsCode) {
        this.$emit('chooseGoods', goodsCode);
      },
      editGoodsNumber(number, goodsCode) {
        this.$emit('editGoodsNumber', {
          goodsCode,
          number
        });
      },
      deleteClick(goodsCode) {
        this.$emit('delete', goodsCode);
      }
    },

    watch: {
      tableData: {
        handler(newValue) {
          this.multipleSelection = newValue;
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>

<style lang="less" scoped>
  .teble {

  }
</style>
