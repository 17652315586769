<template>
  <section class="search">
    <div class="frsbc p500">
      <div class="left frfsc">
        <div class="add_goods mr30" @click="addGoods">添加商品</div>
        <div class="import_goods mr30" @click="batchImportGoods">批量导入商品<div @click.stop="downloadTemplate">下载导入模板</div></div>
        <div class="middle">
          <div class="search_conditions mr15 f">
            <div class="input frsbc p010">
              <div class="search_icon">
                <img src="~assets/images/search.png">
              </div>
              <input class="m05" type="text" v-model="keyWord" placeholder="输入ISBN或商品全名搜索">
              <div class="delete_icon" @click="clearKeyword" v-if="keyWord">
                <img src="~assets/images/delete.png">
              </div>
            </div>
            <div class="buttom" @click="searchClick">搜索</div>
          </div>
        </div>
      </div>
      <div class="right frfec">
        <div class="set_number mr30" @click="batchSetNumber">批量设置数量</div>
        <div class="export_goods" @click="batchExportGoods">批量导出商品</div>
      </div>
    </div>
    
    <ChooseGoods ref="ChooseGoods" :visible.sync="chooseGoodsVisible" @determine="addGoodsHandle" />
    <PopupWindow ref="PopupWindow" :visible.sync="popupWindowVisible" title="批量设置所选商品采购数量" @determine="batchEditNumber" />
    <Import ref="Import" :visible.sync="importVisible" @importGoods="importGoods" />
  </section>
</template>

<script>
  import ChooseGoods from 'components/ChooseGoods/index.vue';
  import PopupWindow from 'components/PopupWindow/index.vue';
  import Import from 'components/Import/index.vue';

  export default {
    components: {
      ChooseGoods,
      PopupWindow,
      Import
    },

    data() {
      return {
        keyWord: '',
        chooseGoodsVisible: false,
        popupWindowVisible: false,
        importVisible: false
      }
    },

    methods: {
      addGoods() {
        this.$refs.ChooseGoods.getTableData();
        this.chooseGoodsVisible = true;
      },
      addGoodsHandle(goodsList) {
        this.$emit('addGoods', goodsList);
      },
      downloadTemplate() {
        this.$emit('downloadImportTemplate');
      },
      batchImportGoods() {
        this.importVisible = true;
      },
      importGoods() {
        this.$emit('importGoods');
      },
      searchClick() {
        this.$emit('search', this.keyWord);
      },
      clearKeyword() {
        this.keyWord = '';
        this.searchClick();
      },
      batchSetNumber() {
        this.popupWindowVisible = true;
      },
      batchEditNumber(formData) {
        this.$emit('batchEditNumber', formData);
      },
      batchExportGoods() {
        this.$emit('batchExportGoods');
      }
    }
  }
</script>

<style lang="less" scoped>
  .search {
    border-bottom: 8px solid var(--colorf2f2f2);
    .left {
      .add_goods {
        width: 90px;
        height: 30px;
        background: var(--color1890ff);
        border-radius: 4px;
        font-size: var(--fontSize16);
        color: var(--colorfff);
        font-weight: 500;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
      }
      .import_goods {
        position: relative;
        width: 120px;
        height: 30px;
        background: var(--colorfff);
        border: 1px solid var(--colord9d9d9);
        border-radius: 4px;
        font-size: var(--fontSize16);
        color: var(--color333);
        font-weight: 500;
        text-align: center;
        line-height: 28px;
        cursor: pointer;
        div {
          position: absolute;
          top: 30px;
          left: 50%;
          transform: translateX(-50%);
          font-size: var(--fontSize12);
          color: var(--color1890ff);
          font-weight: 500;
          white-space: nowrap;
        }
      }
    }

    .middle {
      .search_conditions {
        .input {
          width: 300px;
          height: 30px;
          border: 1px solid var(--colorccc);
          border-right: none;
          .search_icon {
            width: 17px;
            height: 17px;
          }
          input {
            flex: 1;
            font-size: var(--fontSize12);
            color: var(--color999);
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .delete_icon {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }
        .buttom {
          width: 60px;
          height: 30px;
          background: var(--colored6d00);
          font-size: var(--fontSize12);
          color: var(--colorfff);
          font-weight: 500;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
        }
      }
    }

    .right {
      .set_number {
        width: 120px;
        height: 30px;
        background: var(--colorfff);
        border: 1px solid var(--colord9d9d9);
        border-radius: 4px;
        font-size: var(--fontSize16);
        color: var(--color333);
        font-weight: 500;
        text-align: center;
        line-height: 28px;
        cursor: pointer;
      }
      .export_goods {
        width: 120px;
        height: 30px;
        background: var(--colorfff);
        border: 1px solid var(--colord9d9d9);
        border-radius: 4px;
        font-size: var(--fontSize16);
        color: var(--color333);
        font-weight: 500;
        text-align: center;
        line-height: 28px;
        cursor: pointer;
      }
    }
  }
</style>
