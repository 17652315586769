<template>
  <section class="popup_window">
    <el-dialog :visible="visible" width="20%" @close="cancelClick">
      <template slot="title">
        <div class="title">{{title}}</div>
      </template>

      <el-form ref="form" :model="formData" label-width="20px">
        <el-form-item>
          <el-input class="input300" type="text" v-model="formData.quantity" placeholder="请输入采购数量"></el-input>
        </el-form-item>
      </el-form>
      
      <template slot="footer">
        <el-button type="primary" @click="determineClick">确定</el-button>
        <el-button @click="cancelClick">取消</el-button>
      </template>
    </el-dialog>
  </section>
</template>

<script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        formData: {
          quantity: ''
        }
      }
    },

    methods: {
      determineClick() {
        this.$emit('determine', this.formData);
        this.cancelClick();
      },
      cancelClick() {
        this.$emit('update:visible', false);
        this.formData = this.$options.data().formData;
      }
    }
  }
</script>

<style lang="less" scoped>
  .popup_window {
    .title {
      margin: 15px 0 0 15px;
      font-size: var(--fontSize20);
      color: var(--color000);
      font-weight: bold;
    }
  }
</style>
