import request from 'network/request';

const baseURL = '/order/extend/purchase/cart/';

export function downloadImportTemplate() {
  return request({
    url: baseURL + 'downPoImport',
    method: 'get',
    responseType: 'blob'
  });
}
export function getTabelData(params) {
  return request({
    url: baseURL + 'page',
    method: 'get',
    params
  });
}
export function batchEditNumber(params) {
  return request({
    url: baseURL + 'batch/quantity',
    method: 'get',
    params
  });
}
export function batchExportGoods() {
  return request({
    url: baseURL + 'export/po',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    responseType: 'blob'
  });
}
export function chooseGoods(params) {
  return request({
    url: `${baseURL}select/${params}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function allChoose() {
  return request({
    url: baseURL + 'selectAll',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function deleteSelected() {
  return request({
    url: baseURL + 'delete/select',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function deleteNoAvailable() {
  return request({
    url: baseURL + 'delete/outOfStock',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function editGoodsNumber({goodsCode, number}) {
  return request({
    url: `${baseURL}modify/${goodsCode}/${number}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function deleteTabelData(params) {
  return request({
    url: baseURL + 'delete',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
