<template>
  <section class="choose_goods">
    <el-dialog :visible="visible" width="60%" @close="cancelClick">
      <template slot="title">
        <div class="title">选择商品</div>
      </template>

      <el-form ref="form" :model="searchData" inline>
        <el-form-item>
          <el-input class="input300" type="text" v-model="searchData.keyword" placeholder="请输入商品名称查询"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input class="input500" type="text" v-model="searchData.isbn" placeholder="请输入商品ISBN查询，支持批量（用逗号或者空格隔开）"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchClick">查询</el-button>
        </el-form-item>
      </el-form>

      <el-table :data="tableData" @selection-change="handleSelectionChange" stripe border>
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column label="商品ISBN" prop="isbn" align="center"></el-table-column>
        <el-table-column label="商品编号" prop="goodsCode" align="center"></el-table-column>
        <el-table-column label="商品名称" prop="goodsName" align="center"></el-table-column>
        <el-table-column label="定价（元）" prop="basePrice" align="center"></el-table-column>
        <el-table-column label="单价（元）" :prop="regularFlag == 1 ? 'extendPrice' : 'tradePrice'" align="center"></el-table-column>
      </el-table>

      <Pagination :pageIndex.sync="paging.pageIndex" :pageSize.sync="paging.pageSize" :total="paging.total" @change="getTableData" />
      
      <template slot="footer">
        <el-button type="primary" @click="determineClick">确定</el-button>
        <el-button @click="cancelClick">取消</el-button>
      </template>
    </el-dialog>
  </section>
</template>

<script>
  import Pagination from 'components/Pagination/index.vue';
  import {
    getRegularFlag
  } from 'utils/user.js';
  import {
    getGoodsList
  } from 'api/components.js';

  export default {
    components: {
      Pagination
    },

    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        regularFlag: getRegularFlag(), // 1 银卡 2 金卡
        searchData: {
          keyword: '',
          isbn: ''
        },
        paging: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        },
        tableData: [],
        multipleSelection: []
      }
    },

    methods: {
      getTableData() {
        getGoodsList({
          ...this.searchData,
          pageIndex: this.paging.pageIndex,
          pageSize: this.paging.pageSize
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.tableData = res.data.page.records;
          this.paging.total = res.data.page.total;
        });
      },
      searchClick() {
        this.paging.pageIndex = 1;
        this.paging.pageSize = 10;
        this.getTableData();
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      determineClick() {
        this.$emit('determine', this.multipleSelection);
        this.cancelClick();
      },
      cancelClick() {
        this.$emit('update:visible', false);
        this.searchData = this.$options.data().searchData;
        this.paging = this.$options.data().paging;
        this.tableData.length = 0;
        this.multipleSelection.length = 0;
      }
    }
  }
</script>

<style lang="less" scoped>
  .choose_goods {
    .title {
      margin: 15px 0 0 15px;
      font-size: var(--fontSize20);
      color: var(--color000);
      font-weight: bold;
    }
  }
  
</style>
