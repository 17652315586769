<template>
  <section class="import">
    <el-dialog title="批量导入商品" width="15%" :visible="visible" @close="cancelClick">
      <el-upload action="" :http-request="upload" accept=".xls, .xlsx" :show-file-list="false">
        <el-button size="small" type="primary">+ 添加文档</el-button>
      </el-upload>
    </el-dialog>
  </section>
</template>

<script>
  import {Loading} from 'element-ui';
  import axios from 'axios';

  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        timer: null
      }
    },

    methods: {
      upload(params) {
        const loading = Loading.service({
          lock: true,
          text: '文件导入中，请稍后...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let form = new FormData();
        form.append('file', params.file);
        axios({
          url: '/hullo-cloud/order/extend/purchase/cart/poImport',
          // url: 'api/order/extend/purchase/cart/poImport',
          method: 'post',
          data: form,
          headers: {
            client_token: this.$store.state.user.token
          }
        }).then(res => {
          if(res.data.code) {
            this.$message.warning(res.data.message);
            return;
          }
          this.timer = setInterval(() => {
            axios({
              url: '/hullo-cloud/order/extend/purchase/cart/poImport/results',
              // url: 'api/order/extend/purchase/cart/poImport/results',
              method: 'get',
              params: {
                key: res.data.data
              },
              headers: {
                client_token: this.$store.state.user.token
              },
              responseType: 'blob'
            }).then(r => {
              console.log(r)
              let blob = new Blob([r.data], {
                type: "application/octet-stream",
              });
              if(r.data.type == 'application/json') {
                const fileReader = new FileReader();
                fileReader.readAsText(blob,'utf-8');
                fileReader.onload = function () {
                  let msg = JSON.parse(fileReader.result);
                  if(msg.code == 500) {
                    clearInterval(this.timer);
                    loading.close();
                    this.$message.error(res.data.message);
                    return;
                  }
                }
              }else if(r.data.type == 'application/vnd.ms-excel') {
                clearInterval(this.timer);
                loading.close();
                this.$message.success('导入商品成功！');
                let a = document.createElement('a');
                a.href = window.URL.createObjectURL(new Blob([r.data]));
                a.setAttribute('download', '采购单导入商品结果反馈表' + '.xlsx');
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                this.$emit('importGoods');
                this.cancelClick();
              }
            });
          }, 1000);
        });
      },
      cancelClick() {
        this.$emit('update:visible', false);
      }
    }
  }
</script>

<style lang="less" scoped>
  .import {

  }
</style>
